<template>
  <b-sidebar
    id="add-new-team-sidebar"
    :visible="isAddNewTeamSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-team-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t("message.team.createNewTeam") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewteam())"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="nome"
            rules="required"
          >
            <b-form-group
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tableHeader.name") }}
              </template>
              <b-form-input
                id="name"
                v-model="teamData.name"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="manager"
            rules="required"
          >
            <b-form-group
              label-for="members"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.team.managers") }}
              </template>
              <v-select
                multiple
                v-model="teamData.managers"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userManager"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="team-role"
              />
            </b-form-group>
          </validation-provider>

          <!-- Team -->
          <validation-provider
            #default="validationContext"
            name="membros"
            rules="required"
          >
            <b-form-group
              label-for="members"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.team.members") }}
              </template>
              <v-select
                multiple
                v-model="teamData.members"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="memberOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="team-role"
              />
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewTeamSidebarActive",
    event: "update:is-add-new-team-sidebar-active",
  },
  props: {
    isAddNewTeamSidebarActive: {
      type: Boolean,
      required: true,
    },
    memberOptions: {
      type: Array,
      required: true,
    },
    userManager: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,

      //Param
      paramData: store.state.user.paramData,

      userData: store.state.user.userData,

      disabledButton: false,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  methods: {
    submitNewteam() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.disabledButton = true;
          this.onSubmit();

          axios({
            method: "post",
            url: `${URL_API}team`,
            headers: getHeader(this.userData),
            data: this.teamData,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("message.toast.create"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: `Equipe criada com sucesso`,
                },
              });
            })
            .then(() => {
              this.$root.$emit("newListTeam", null);
              this.disabledButton = false;
            })
            .catch((error) => {
              this.disabledButton = false;
              console.log(error);
            });
        }
      });
    },
  },

  setup(props, { emit }) {
    const blankteamData = {};

    const teamData = ref(JSON.parse(JSON.stringify(blankteamData)));
    const resetteamData = () => {
      teamData.value = JSON.parse(JSON.stringify(blankteamData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-team-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetteamData);

    return {
      teamData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-team-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
